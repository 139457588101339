import { useState, useEffect, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useLocation, matchPath } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { AuthContext } from "context";
import "./App.css"

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { userData: { role, token } } = useContext(AuthContext);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      
      return null;
    });
    
    const configsButton = (
      <></>
    //   <MDBox
    //   display="flex"
    //   justifyContent="center"
    //   alignItems="center"
    //   width="3.25rem"
    //   height="3.25rem"
    //   bgColor="white"
    //   shadow="sm"
    //   borderRadius="50%"
    //   position="fixed"
    //   right="2rem"
    //   bottom="2rem"
    //   zIndex={99}
    //   color="dark"
    //   sx={{ cursor: "pointer" }}
    //   onClick={handleConfiguratorOpen}
    // >
    //  {/* settings <Icon fontSize="small" color="inherit">
        
    //   </Icon> */}
    // </MDBox>
  );

  // Use matchPath to check if the current path matches /reset-password/:token
  const isResetPasswordRoute = matchPath("/reset-password/:token",pathname);

  const isConsentRoute = matchPath("/consent/:token", pathname);
  const isAdminApproval = matchPath("/admin/approve/:token", pathname);

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && !(isResetPasswordRoute || isConsentRoute || isAdminApproval) && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2 //"
              routes={routes.filter(obj => {
                if (role !== 'admin') {
                  return obj.key !== "sign-in" && obj.key !== "sign-up" && obj.key !== "physician" && obj.key !== "forgot-password" && obj.key !== "consent-manage" && obj.key !== "dashboard" && obj.key !== "admin-approval" && obj.key !== "otp-verification" && obj.key !== "patient-details";
                } else {
                  return obj.key !== "sign-in" && obj.key !== "sign-up" && obj.key !== "forgot-password" && obj.key !== "consent-manage"  && obj.key !== "admin-approval" && obj.key !== "otp-verification" && obj.key !== "patient-details" && obj.key !== "physician";
                }
              })}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && !(isResetPasswordRoute || isConsentRoute|| isAdminApproval) && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard 2"
            routes={routes.filter(obj => {
              if (role !== 'admin') {
                return obj.key !== "sign-in" && obj.key !== "sign-up" && obj.key !== "physician" && obj.key !== "forgot-password" && obj.key !== "consent-manage" && obj.key !== "dashboard" && obj.key !== "admin-approval" && obj.key !== "otp-verification" && obj.key !== "patient-details";
              } else {
                return obj.key !== "sign-in" && obj.key !== "sign-up" && obj.key !== "forgot-password" && obj.key !== "consent-manage"  && obj.key !== "admin-approval" && obj.key !== "otp-verification" && obj.key !== "patient-details" && obj.key !== "physician";
              }
            })}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}
