import { useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function ResetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `https://${process.env.REACT_APP_API_URL}/api/auth/reset-password/${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newPassword, confirmPassword }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
        // setTimeout(() => {
        navigate("/authentication/sign-in");
        // }, 2000);
      } else {
        setResponseMessage(data.message);
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  return (
    <Card sx={{ padding: 4, maxWidth: 600, margin: "auto", marginTop: "50px" }}>
      <MDBox mb={3}>
        <MDTypography variant="h4" textAlign="center">
          Reset Password
        </MDTypography>
      </MDBox>
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type={showNewPassword ? "text" : "password"}
            label="New Password"
            fullWidth
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            fullWidth
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        </MDBox>
        <MDBox mt={4}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSubmit}
          >
            Reset Password
          </MDButton>
        </MDBox>
        {responseMessage && (
          <MDBox mt={2}>
            <MDTypography variant="body2" color="text">
              {responseMessage}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </Card>
  );
}

export default ResetPassword;
