import React from "react"
import { Scatter } from "react-chartjs-2"
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js"
import "chartjs-adapter-date-fns"
import { enUS } from "date-fns/locale"

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale)

const ScatterPlotMatrixChart = () => {
  // Raw data
  const rawData = [
    {
      visitDate: "2024-11-10T18:30:00.000Z",
      npsScore: 3,
      ossTest: "red",
      comorbidities: {
        Allergies: {
          Used: false,
        },
      },
    },
    {
      visitDate: "2024-11-15T18:30:00.000Z",
      npsScore: 4,
      ossTest: "green",
      comorbidities: {
        Allergies: {
          Used: true,
        },
      },
    },
  ]

  // OSS Test Score Mapping
  const ossScoreMapping = {
    white: 0,
    green: 1,
    orange: 2,
    red: 3,
    black: 4,
  }

  // Transform data for plotting
  const transformedData = rawData.map((data) => ({
    visitDate: new Date(data.visitDate).getTime(),
    npsScore: data.npsScore,
    ossTest: ossScoreMapping[data.ossTest] || 0,
    allergies: data.comorbidities.Allergies.Used ? 1 : 0,
  }))

  // Chart Options Template
  const chartOptions = (yLabel, isLastChart) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        position: "bottom",
        title: {
          display: isLastChart,
          text: isLastChart ? "Visit Date" : "",
          color:'white'
        },
        time: {
          unit: "day",
          tooltipFormat: "MMM d, yyyy",
          displayFormats: {
            day: "MMM d",
          },
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        grid: {
          display: true,
          color:'#49a3f1'
        },
        ticks: {
          display: isLastChart, // Only show x-axis ticks for the last chart
          color:'white'
        },
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: yLabel,
          color:'white'
        },
        grid: {
          display: true,
            color:'#49a3f1'
        },
        ticks: {
            color:'white'
          }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${yLabel}: ${context.parsed.y.toFixed(2)}, Date: ${new Date(
              context.parsed.x
            ).toLocaleDateString()}`
          },
        },
      },
    },
  })

  const metrics = [
    { key: "npsScore", label: "NPS Score", color: "yellow" },
    { key: "ossTest", label: "OSS Test Score", color: "pink" },
    { key: "allergies", label: "Allergies", color: "violet" },
  ]

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <h2 style={{color:'white'}}>Scatter Plot Matrix</h2>
      <div className="space-y-8" >
        {metrics.map((metric, index) => (
          <div key={metric.key} style={{ width: index === metrics.length - 1 ? '100.5%%' : '98%', height: index === metrics.length - 1 ? '250px' : '200px',
        //   borderBottom:'1px solid white'
          }} > 
            <Scatter
              data={{
                datasets: [
                  {
                    label: metric.label,
                    data: transformedData.map((d) => ({
                      x: d.visitDate,
                      y: d[metric.key],
                    })),
                    backgroundColor: metric.color,
                  },
                ],
              }}
              options={{
                ...chartOptions(metric.label, index === metrics.length - 1),
                maintainAspectRatio: false,
              }}
            />
            {index !== metrics.length - 1 &&
            <div style={{backgroundColor:"wheat", height:"1px",marginLeft: "46px",marginRight: "4px"}} >
            </div>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScatterPlotMatrixChart

