import React, { createContext, useState, useEffect } from "react";
import axios from "axios";


// Create the context
export const ApiContext = createContext();

// Create the provider component
export const ApiProvider = ({ children , selectedHospital, selectedPatient="6744597ac68bb76daa7a702d" }) => {
  console.log("comin in API Context",selectedPatient);
  const [data, setData] = useState({
    biologicTherapyCountByGender: null,
    biologicTherapyCountByAge: null,
    biologicTherapyProportion: null,
    biologicTherapySwitches: null,
    biologicTherapySwitches: null,
    ostTestPatients:null,
    ostTestPatientsByVisit:null,
    ostTestByProgress:null,
    ostTestByProgressByage:null,
    ostTestPatientsByage:null,
    avgTreatmentNpsByVisit:null,
    avgNpsByGender:null,
    averageNPS: null,
    comorbiditiesByPerc:null,
    comorbiditiesByfreq:null,
    surgicalDataByYear:null,
    temporalTrendsNps:null,
    avgSnotByCategory:null,
    temporalTrendsSnot:null,
    surgicalProcedureByPerc:null,
    patientByMedication:null,
    genderStatsPatients:null,
    ageDitributionPatient:null,
    // scatterMatrixData:null,
    testScoreByAge:null,
    loading: true,
    error: null,
  });





  function transformDataToBarChart(data) {
    const therapies = Object.keys(data.female || data.male || {}); // Get therapies from female, male, or an empty object
    const femaleData = therapies.map((therapy) => (data.female?.[therapy] || 0)); // Default to 0 if no female data
    const maleData = therapies.map((therapy) => (data.male?.[therapy] || 0)); // Default to 0 if no male data
  
    return {
      labels: therapies, // X-axis labels (therapies)
      datasets: [
        {
          label: "Female", // Label for the first dataset
          data: femaleData, // Data for females
          backgroundColor: "rgba(255, 99, 132, 0.8)", // Color for female bars
          borderColor: "rgba(255, 99, 132, 1)", // Border color for female bars
          borderWidth: 1,
        },
        {
          label: "Male", // Label for the second dataset
          data: maleData, // Data for males
          backgroundColor: "rgba(54, 162, 235, 0.8)", // Color for male bars
          borderColor: "rgba(54, 162, 235, 1)", // Border color for male bars
          borderWidth: 1,
        },
      ],
    };
  }

  function transformAgeGroupDataToBarChart(data) {
    // Extract all unique therapies across all age groups
    const labels = [...new Set(Object.values(data).flatMap(Object.keys))];
  
    const ageGroups = Object.keys(data); // Extract age groups (e.g., "18-30", "31-45")
  
    const datasets = ageGroups.map((ageGroup) => ({
      label: ageGroup, // Label for each dataset (age group)
      data: labels.map((therapy) => data[ageGroup][therapy] || 0), // Use 0 if therapy is not defined for an age group
      backgroundColor: getRandomColor(), // Generate a unique background color
      borderColor: getRandomColor(), // Generate a unique border color
      borderWidth: 1,
    }));
  
    return {
      labels, // X-axis labels (therapies)
      datasets, // Age group datasets
    };
  }
  
  // Helper function to generate random colors
  function getRandomColor() {
    const colorPalette = [
      "rgba(255, 99, 132, 0.8)", 
      "rgba(54, 162, 235, 0.8)", 
      "rgba(255, 206, 86, 0.8)", 
      "rgba(75, 192, 192, 0.8)", 
      "rgba(153, 102, 255, 0.8)",
      "rgba(255, 159, 64, 0.8)", 
      "rgba(240, 128, 128, 0.8)",
      "rgba(144, 238, 144, 0.8)",
      "rgba(173, 216, 230, 0.8)",
      "rgba(255, 182, 193, 0.8)",
    ];
  
    // Randomly pick a color from the palette
    const randomIndex = Math.floor(Math.random() * colorPalette.length);
    return colorPalette[randomIndex];
  }

  function transformTherapyMetricDataToBarChart(data) {
    const labels = Object.keys(data); // Extract therapy names as labels
    const values = Object.values(data); // Extract corresponding values
  
    return {
      labels, // X-axis labels (therapies)
      datasets: [
        {
          label: "Metric Value", // Label for the dataset
          data: values, // Metric values
          backgroundColor: labels.map(() => getRandomColor()), // Generate random colors for bars
          borderColor: labels.map(() => getRandomColor()), // Generate random border colors
          borderWidth: 1,
        },
      ],
    };
  }

  function transformSwitchDataToStackedBarChart(data) {
    const therapies = Object.keys(data); // Extract therapy names
    const reasons = new Set(); // Use a Set to collect all unique reasons
    
    // Collect all unique switch reasons
    therapies.forEach((therapy) => {
      Object.keys(data[therapy].switchReasons).forEach((reason) =>
        reasons.add(reason)
      );
    });
  
    // Convert Set to Array to use as labels
    const reasonLabels = Array.from(reasons);
  
    // Build datasets for each reason
    const datasets = reasonLabels.map((reason) => {
      return {
        label: reason, // Dataset label
        data: therapies.map(
          (therapy) => data[therapy].switchReasons[reason] || 0 // Default to 0 if no value for the reason
        ),
        backgroundColor: getRandomColor(), // Assign a unique color
        borderColor: getRandomColor(),
        borderWidth: 1,
      };
    });
  
    return {
      labels: therapies, // X-axis labels (therapies)
      datasets,
    };
  }

  const processNpsData = (data) => {
    // Extract all possible labels (years or "Unknown") dynamically
    const labels = [
      ...new Set(
        Object.values(data)
          .flatMap((years) => Object.keys(years))
          .filter(Boolean) // Remove any null or undefined values
      ),
    ];
  
    const datasets = [];
  
    // Iterate over each biologic to generate the dataset
    Object.entries(data).forEach(([biologic, years], index) => {
      const averageNPS = labels.map(
        (label) => parseFloat(years[label]?.averageNPS || 0)
      );
  
      // Add the dataset for each biologic
      datasets.push({
        label: biologic,
        data: averageNPS,
        borderColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"][index % 4], // Different color for each biologic
        backgroundColor: "rgba(0,0,0,0)", // Transparent fill for the line
        pointBorderColor: "#fff", // White dots for each data point
        tension: 0.4, // Smooth line
      });
    });
  
    // Return the final chart data structure with labels and datasets
    return { labels, datasets };
  };

  function formatGenderColorData(data) {
    const colors = Object.keys(data.female || data.male || {}); // Get colors from female, male, or an empty object
    const genders = ["female", "male"]; // Predefined genders to ensure both are included
  
    // Generate datasets for each gender
    const datasets = genders.map((gender) => ({
      label: gender.charAt(0).toUpperCase() + gender.slice(1), // Capitalize gender labels
      data: colors.map((color) => (data[gender]?.[color] || 0)), // Default to 0 if data is missing
      backgroundColor: getRandomColor(), // Assign a unique color for each gender
    }));
  
    // Return the formatted data structure for Chart.js
    return {
      labels: colors, // Use color categories as labels
      datasets, // Include the generated datasets
    };
  }



  
function convertDataToChartData(data) {
  // Define a mapping for the ossTest values
  const ossTestMap = {
    red: 2,
    orange: 1,
    green: 0
  };

  // Prepare the chart dataset for both male and female patients
  const chartData = {
    labels: [], // This will store the dates for the X-axis
    datasets: []
  };

  // Function to process each gender's data
  function processGenderData(genderData = [], genderLabel) {
    genderData.forEach((patient, index) => {
      const patientLabel = `Patient ${index + 1} (${genderLabel})`;

      // Extract visit dates and ossTest values for this patient
      const progressionData = patient.progression.map(progress => {
        // Map ossTest value to a number and store date as label
        return {
          date: new Date(progress.visitDate).toLocaleDateString(), // Format date
          ossTestValue: ossTestMap[progress.ossTest] || 0 // Default to 0 if unknown
        };
      });

      // Add the visit dates to the labels if not already added
      progressionData.forEach(dataPoint => {
        if (!chartData.labels.includes(dataPoint.date)) {
          chartData.labels.push(dataPoint.date);
        }
      });

      // Add a dataset for this patient
      chartData.datasets.push({
        label: patientLabel,
        data: progressionData.map(dataPoint => dataPoint.ossTestValue),
        fill: false,
        borderColor: `hsl(${Math.random() * 360}, 100%, 50%)`, // Random color for each patient
        tension: 0.1
      });
    });
  }

  // Ensure male and female data exist as arrays
  const maleData = data.male || [];
  const femaleData = data.female || [];

  // Process male and female data
  processGenderData(maleData, 'Male');
  processGenderData(femaleData, 'Female');

  // Sort the labels (dates) in ascending order
  chartData.labels.sort((a, b) => new Date(a) - new Date(b));

  return chartData;
};


  
  function convertToStackedBarChartData(data) {
    // Extract unique visit dates for the X-axis labels
    const uniqueDates = [...new Set(data.map((item) => item.currentVisitDate))];
  
    // Define possible OSS test colors
    const colors = ["red", "orange", "green"];
  
    // Initialize datasets for previousOssTest and currentOssTest
    const datasets = colors.map((color) => ({
      label: `Previous - ${color}`,
      backgroundColor: color,
      stack: "Previous",
      data: uniqueDates.map((date) => {
        const relatedData = data.filter(
          (item) => item.currentVisitDate === date
        );
        return relatedData.filter((item) => item.previousOssTest === color).length;
      }),
    })).concat(
      colors.map((color) => ({
        label: `Current - ${color}`,
        backgroundColor: color,
        stack: "Current",
        data: uniqueDates.map((date) => {
          const relatedData = data.filter(
            (item) => item.currentVisitDate === date
          );
          return relatedData.filter((item) => item.currentOssTest === color).length;
        }),
      }))
    );
  
    // Return chart configuration
    return {
      labels: uniqueDates.map((date) =>
        new Date(date).toLocaleDateString()
      ),
      datasets,
    };
  }
  function convertToLineChartDataWithAgeGroups(data) {
    const ageGroups = Object.keys(data); // Extract the age groups (e.g., "18-30")
    const colors = ["red", "orange", "green"]; // Possible OSS test colors
  
    // Initialize an object to track visit dates and counts for each OSS test and age group
    const visitDateMap = {};
  
    // Loop through age groups and process patient progression data
    ageGroups.forEach((ageGroup) => {
      const patients = data[ageGroup]; // Array of patients in the age group
  
      patients.forEach((patient) => {
        patient.progression.forEach(({ visitDate, ossTest }) => {
          const date = new Date(visitDate).toLocaleDateString(); // Format the date
          if (!visitDateMap[date]) {
            visitDateMap[date] = { red: 0, orange: 0, green: 0 };
          }
          visitDateMap[date][ossTest]++;
        });
      });
    });
  
    // Extract sorted dates for the X-axis labels
    const sortedDates = Object.keys(visitDateMap).sort(
      (a, b) => new Date(a) - new Date(b)
    );
  
    // Prepare datasets for each age group
    const datasets = ageGroups.map((ageGroup) => {
      const ageGroupData = colors.map((color) => ({
        label: `${ageGroup} - ${color.toUpperCase()}`,
        borderColor: color,
        backgroundColor: color,
        tension: 0.4,
        fill: false,
        data: sortedDates.map((date) => visitDateMap[date][color] || 0),
      }));
  
      return ageGroupData;
    });
  
    // Flatten the datasets to combine all the age groups' data
    const flattenedDatasets = datasets.flat();
  
    // Return the chart data configuration
    return {
      labels: sortedDates, // X-axis labels
      datasets: flattenedDatasets, // Y-axis datasets for each age group
    };
  }

  function prepareBarChartDataOfOstByAge(data) {
    // Get the age groups (e.g., "18-30") from the data
    const ageGroups = Object.keys(data);
  
    // Prepare datasets for each age group
    const datasets = ageGroups.map((ageGroup) => {
      // Extract the counts for each color in the age group
      const colors = Object.keys(data[ageGroup]);
      const counts = colors.map((color) => data[ageGroup][color]);
  
      return {
        label: `${ageGroup} Age Group`, // Label for the age group
        data: counts, // Data for each color count in the age group
        backgroundColor: colors.map((color) => {
          switch (color) {
            case "orange": return "rgba(255, 165, 0, 0.6)"; // Orange color
            case "black": return "rgba(0, 0, 0, 0.6)"; // Black color
            case "green": return "rgba(0, 128, 0, 0.6)"; // Green color
            case "red": return "rgba(255, 0, 0, 0.6)"; // Red color
            default: return "rgba(0, 0, 0, 0.6)"; // Default color
          }
        }),
        borderColor: colors.map((color) => {
          switch (color) {
            case "orange": return "rgba(255, 165, 0, 1)"; // Orange border
            case "black": return "rgba(0, 0, 0, 1)"; // Black border
            case "green": return "rgba(0, 128, 0, 1)"; // Green border
            case "red": return "rgba(255, 0, 0, 1)"; // Red border
            default: return "rgba(0, 0, 0, 1)"; // Default border color
          }
        }),
        borderWidth: 1, // Border width for the bars
      };
    });
  
    // Extract labels (colors) from the first age group for the X-axis
    const labels = Object.keys(data[ageGroups[0]]);
  
    // Return the chart data configuration
    return {
      labels: labels, // X-axis labels for the color categories
      datasets: datasets, // Y-axis datasets for each age group
    };
  }

  function avgTreatmentNpsByVisitByLineChart(rawData) {
    const labels = [];
    const data = [];
  
    // Loop through the raw data object
    Object.keys(rawData).forEach(date => {
      labels.push(date); // Add dates as labels
      data.push(rawData[date]); // Add NPS values as data points
    });
  
    return {
      labels, // Dates for the x-axis
      datasets: [
        {
          label: "Average NPS Over Time", // Label for the dataset
          data, // NPS values for the y-axis
          borderColor: "rgba(75, 192, 192, 1)", // Line color
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Area under the line color
          borderWidth: 2,
          tension: 0.4, // Smoothness of the line
        },
      ],
    };
  }

  function convertToAvgNpsBoxPlotData(data) {
    const categories = Object.keys(data); // Extract keys (male, female, other)
    const averages = Object.values(data); // Extract values (averages)
  
    // Function to generate plausible ranges for box plot
    const generateBoxPlotRange = (average) => {
      const variance = average * 0.1; // Define a variance (10% of average for demonstration)
      return {
        min: Math.max(0, average - 2 * variance), // Ensure min is not below 0
        q1: Math.max(0, average - variance),
        median: average,
        q3: average + variance,
        max: average + 2 * variance,
      };
    };
  
    // Transform data into Box Plot format
    const boxPlotData = {
      labels: categories, // X-axis labels (e.g., male, female, other)
      datasets: [
        {
          label: "Avg NPS Distribution by Gender",
          data: averages.map((avg) => generateBoxPlotRange(avg)), // Map averages to box plot ranges
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  
    return boxPlotData;
  }

  function convertToComorbiditiesPieChartData(data) {
    const percentages = data.percentages; // Extract percentages object
    const labels = [];
    const values = [];
  
    // Loop through the percentages object to extract labels and values
    Object.entries(percentages).forEach(([key, value]) => {
      const percentage = parseFloat(value);
      if (percentage > 0) { // Include only non-zero values
        labels.push(key); // Use the key as the label
        values.push(percentage); // Add the numeric value
      }
    });
  
    // Create the Chart.js compatible Pie Chart data
    const pieChartData = {
      labels, // Categories (e.g., ChurgStraussSyndrome, Asthma)
      datasets: [
        {
          label: "Percentage Distribution",
          data: values, // Values (percentages)
          backgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#66FF66", "#FF6666",
          ], // Add colors for slices
          hoverBackgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#66FF66", "#FF6666",
          ], // Add hover colors for slices
        },
      ],
    };
  
    return pieChartData;
  }

  function convertToComorbiditiesBarChartData(data) {
    const categories = []; 
    const maleData = [];
    const femaleData = [];
    const otherData = [];
  
    // Extract data from each group (male, female, other)
    Object.entries(data).forEach(([gender, diseases]) => {
      // Loop through diseases and build up the dataset
      Object.entries(diseases).forEach(([disease, count]) => {
        // Add the disease to categories if it's not already present
        if (!categories.includes(disease)) {
          categories.push(disease);
        }
        
        // Push the data to the respective gender dataset
        if (gender === "male") {
          maleData.push(count);
        } else if (gender === "female") {
          femaleData.push(count);
        } else {
          otherData.push(count);
        }
      });
    });
  
    // Ensure all datasets align with the categories
    // Fill missing categories with 0
    const fillMissingData = (dataset, categories) => {
      return categories.map((category) => {
        // Find the count for the category in the dataset or 0 if it doesn't exist
        return dataset[category] || 0;
      });
    };
  
    // Prepare data for Bar Chart
    const barChartData = {
      labels: categories, // Disease categories as labels on X-axis
      datasets: [
        {
          label: "Male",
          data: fillMissingData(data.male || {}, categories),
          backgroundColor: "#FF6384",
        },
        {
          label: "Female",
          data: fillMissingData(data.female || {}, categories),
          backgroundColor: "#36A2EB",
        },
        {
          label: "Other",
          data: fillMissingData(data.other || {}, categories),
          backgroundColor: "#FFCE56",
        },
      ],
    };
  
    return barChartData;
  }

  function convertToBarChartDataByYear(data) {
    const years = Object.keys(data); // Extract the years as labels for the X-axis
    const procedures = new Set(); // A set to store all unique procedures across all years
  
    // Loop through the data to collect all unique procedures
    years.forEach((year) => {
      Object.keys(data[year]).forEach((procedure) => {
        procedures.add(procedure);
      });
    });
  
    // Convert the Set of procedures to an array for easier handling
    const procedureArray = Array.from(procedures);
  
    // Prepare datasets for each procedure (with data for each year)
    const datasets = procedureArray.map((procedure) => {
      const dataset = {
        label: procedure,
        data: [],
        backgroundColor: getRandomColor(), // Random color for each procedure
      };
  
      // Loop through the years and fill the data for each procedure
      years.forEach((year) => {
        dataset.data.push(data[year][procedure] || 0); // Push the count or 0 if the procedure is not present in the year
      });
  
      return dataset;
    });
  
    // Prepare the data structure for the bar chart
    const barChartData = {
      labels: years, // Year as X-axis labels
      datasets: datasets, // Datasets for each procedure
    };
  
    return barChartData;
  }

  function convertDataForLineChart(data) {
    const chartData = {
      labels: [],  // For x-axis (dates)
      datasets: [{
        label: 'Average NPS',  // The label for the line chart
        data: [],  // For y-axis (average NPS values)
        fill: false,  // Whether to fill the area under the line
        borderColor: 'rgba(75,192,192,1)',  // Line color
        tension: 0.1  // Line smoothing
      }]
    };
  
    // Iterate through the data object to populate chartData
    Object.keys(data).forEach(date => {
      const { totalNPS, count } = data[date];
      const averageNPS = count > 0 ? totalNPS / count : 0; 
      
      
      
       // Calculate average NPS
  
      // Add date to the labels array (x-axis)
      chartData.labels.push(date);
  
      // Add the calculated averageNPS to the data array (y-axis)
      chartData.datasets[0].data.push(averageNPS);
    });
  
    return chartData;
  }
  
  const convertToChartDataSnotTT = (responseData) => {
    // Ensure the data is sorted by date
    const sortedData = [...responseData].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    // Extract labels and data points
    const labels = sortedData.map(item => item.date); // Dates for the x-axis
    const dataPoints = sortedData.map(item => item.averageTotalSnotScore); // Scores for the y-axis
  
    // Return formatted chart data
    return {
      labels,
      datasets: [
        {
          label: "Average Total SNOT-22 Score",
          data: dataPoints,
          fill: false,
          borderColor: "rgba(75, 192, 192, 1)", // Line color
          backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
          tension: 0.1, // Curve of the line (0 for straight lines)
        },
      ],
    };
  };

  const convertSurgicalPercentage = (apiData) => {
    const labels = Object.keys(apiData);
    const data = Object.values(apiData).map(value => parseFloat(value)); // Convert strings to numbers
  
    // Generate dynamic colors for each label using getRandomColor
    const backgroundColor = labels.map(() => getRandomColor());
  
    // Generate chart data structure
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor, // Use dynamically generated colors
        },
      ],
    };
  };
  
  function transformDataForScatterChartByMedication(data) {
    // Define background colors for each medication type
    const medicationColors = {
      TopicalCorticosteroids: "red",
      Antihistamines: "yellow",
      TopicalCorticosteroidsAntihistamines: "purple",
      OralCorticosteroids: "pink",
    };
  
    // Initialize the final dataset structure
    const scatterData = {
      datasets: [
        { label: "TopicalCorticosteroids", data: [], backgroundColor: medicationColors.TopicalCorticosteroids },
        { label: "Antihistamines", data: [], backgroundColor: medicationColors.Antihistamines },
        { label: "TopicalCorticosteroidsAntihistamines", data: [], backgroundColor: medicationColors.TopicalCorticosteroidsAntihistamines },
        { label: "OralCorticosteroids", data: [], backgroundColor: medicationColors.OralCorticosteroids },
      ],
    };
  
    // Iterate through the provided data and populate the scatterData
    data.forEach(item => {
      if (item.clinicalScore !== null) {
        // Map 'clinicalScoreType' to numerical values for x-axis (0 for 'NPS', 1 for 'OSS Test')
        const xValue = item.clinicalScoreType === "NPS" ? 0 : 1;
  
        // Add the data point to the corresponding medication type
        const dataset = scatterData.datasets.find(ds => ds.label === item.medicationType);
        if (dataset) {
          dataset.data.push({
            x: xValue,
            y: item.clinicalScore,
          });
        }
      }
    });
  
    return scatterData;
  }

  const convertToGenderStats = (apiResponse) => {
    if (!apiResponse || !apiResponse.genderPercentages) {
      throw new Error("Invalid API response format");
    }
  
    const { genderPercentages } = apiResponse;
  
    const labels = Object.keys(genderPercentages).map((key) =>
      key.charAt(0).toUpperCase() + key.slice(1)
    );
  
    const data = Object.values(genderPercentages).map((value) =>
      parseFloat(value.replace('%', ''))
    );
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#36A2EB", "#FF6384"], // Blue for Male, Red for Female
          hoverBackgroundColor: ["#5AB3F7", "#FF7C9C"], // Slightly lighter shades
          borderWidth: 1,
        },
      ],
    };
  };

  function transformToAgeDistHistogram(data) {
    if (!data?.ageDistribution) {
      throw new Error("Invalid data format: ageDistribution key is missing");
    }
  
    const ageDistribution = data.ageDistribution;
  
    // Extract labels and values from the ageDistribution object
    const labels = Object.keys(ageDistribution);
    const values = Object.values(ageDistribution);
  
    // Generate background colors for each label
    const backgroundColors = labels.map((label, index) => {
      const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
      return colors[index % colors.length]; // Cycle through colors if there are more labels than colors
    });
  
    // Create the chart data object
    const chartData = {
      labels, // Age range labels
      datasets: [
        {
          label: "Age Distribution",
          data: values, // Frequency values for each age range
          backgroundColor: backgroundColors, // Use dynamic colors
        },
      ],
      xLabel: "Age Ranges", // Label for the x-axis
      yLabel: "Number of Patients", // Label for the y-axis
    };
  
    return chartData;
  }

  function calculateBoxPlotStats(scores) {
    if (scores.length === 0) return { min: null, q1: null, median: null, q3: null, max: null, whiskerMin: null, whiskerMax: null };
  
    scores.sort((a, b) => a - b);
  
    const min = scores[0];
    const max = scores[scores.length - 1];
    const median = getMedian(scores);
    const q1 = getQuartile(scores, 0.25);
    const q3 = getQuartile(scores, 0.75);
  
    // Whiskers are typically the min and max within 1.5 * IQR (Interquartile range)
    const iqr = q3 - q1;
    const whiskerMin = Math.max(min, q1 - 1.5 * iqr);
    const whiskerMax = Math.min(max, q3 + 1.5 * iqr);
  
    return { min, q1, median, q3, max, whiskerMin, whiskerMax };
  }
  
  function getMedian(scores) {
    const mid = Math.floor(scores.length / 2);
    if (scores.length % 2 === 0) {
      return (scores[mid - 1] + scores[mid]) / 2;
    }
    return scores[mid];
  }
  
  function getQuartile(scores, quartile) {
    const index = Math.floor(scores.length * quartile);
    return scores[index];
  }
  
  function transformDataForBoxPlot(data) {
    const transformedData = {
      labels: [],
      datasets: [
        {
          label: "Avg NPS Distribution by Age Range",
          data: [],
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1
        },
        {
          label: "Avg OSS Distribution by Age Range",
          data: [],
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1
        }
      ]
    };
  
    data.forEach(item => {
      transformedData.labels.push(item.range);
  
      // NPS Scores Boxplot
      const npsStats = calculateBoxPlotStats(item.npsScores);
      transformedData.datasets[0].data.push({
        min: npsStats.min,
        q1: npsStats.q1,
        median: npsStats.median,
        q3: npsStats.q3,
        max: npsStats.max,
        whiskerMin: npsStats.whiskerMin,
        whiskerMax: npsStats.whiskerMax
      });
  
      // OSS Scores Boxplot
      const ossStats = calculateBoxPlotStats(item.ossScores);
      transformedData.datasets[1].data.push({
        min: ossStats.min,
        q1: ossStats.q1,
        median: ossStats.median,
        q3: ossStats.q3,
        max: ossStats.max,
        whiskerMin: ossStats.whiskerMin,
        whiskerMax: ossStats.whiskerMax
      });
    });
  
    return transformedData;
  }
  
  function transformMatrixChart(data) {
    return {
      datasets: [
        {
          label: "Scatter Matrix",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          borderColor: "rgba(75, 192, 192, 1)",
          pointRadius: 5,
          data: data.map((entry) => ({
            x: entry.ossTest,
            y: entry.npsScore,
            visitDate: new Date(entry.visitDate).toLocaleDateString(),
          })),
        },
      ],
    };
  };
  

  useEffect(() => {
    if (!selectedHospital) return; // Ensure that selectedHospital is defined before making the API call.
    if (!selectedPatient)  return;
  
    const fetchData = async () => {
      setData((prev) => ({ ...prev, loading: true, error: null })); // Set loading to true before starting API calls
      try {
        const responses  = await Promise.allSettled([
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/biologic-therapy-count?groupBy=gender&hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/biologic-therapy-count?groupBy=age&hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/biologic-therapy-propertion?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/biologic-therapy-switches?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/average-nps?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/ost-test?hospital=${selectedHospital}&groupBy=gender`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/ost-test-by-visit?patientId=${selectedPatient}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/ost-test-by-progress?patientId=${selectedPatient}&groupBy=gender&hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/ost-test-by-progress?patientId=${selectedPatient}&groupBy=age&hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/ost-test?groupBy=age&hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/average-treatment-nps?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/nps-by-gender?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/percentage-comorbidities?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/comorbidities-frequency?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/surgical-procedure-by-year?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/temporal-trends-nps?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/snot-by-category?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/temporal-trends-snot?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/surgical-percentage-distribution?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/medications-distributions?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/medication-correlation?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/gender-stats?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/age-distribution?hospital=${selectedHospital}`
          ),
          axios.get(
            `https://${process.env.REACT_APP_API_URL}/api/analytics/compare-by-age`
          ),
          // axios.get(
          //   `https://${process.env.REACT_APP_API_URL}/api/analytics/scatter-plot-data?patientId=674458d2c68bb76daa7a700d`
          // ),
        ]);

        const results = responses.map((response, index) => {
          if (response.status === 'fulfilled') {
            return response.value; // If fulfilled, return the response data
          } else {
            console.error(`API ${index} failed with error: ${response.reason?.message || response.reason}`);
            return null; // Return null for failed requests
          }
        });

        const [
          countResByGender, countResByAge, proportionRes, switchesRes, npsRes,
          ostTestPatients, ostTestPatientsByVisit, ostTestByProgress, ostTestByProgressByage,
          ostTestPatientsByage, avgTreatmentNpsByVisit, avgNpsByGender, comorbiditiesByPerc,
          comorbiditiesByfreq, surgicalDataByYear, temporalTrendsNps, avgSnotByCategory,
          temporalTrendsSnot, surgicalProcedureByPerc, patientByMedication, medicationCorrelation,
          genderStatsPatients, ageDitributionPatient, testScoreByAge ,
        ] = results;
  
        // Store the data after fetching
        try {
          setData({
            biologicTherapyCountByGender: safeTransform(
              () => transformDataToBarChart(countResByGender.data.data),
              "biologicTherapyCountByGender"
            ),
            biologicTherapyCountByAge: safeTransform(
              () => transformAgeGroupDataToBarChart(countResByAge.data.data),
              "biologicTherapyCountByAge"
            ),
            biologicTherapyProportion: safeTransform(
              () => transformTherapyMetricDataToBarChart(proportionRes.data.data),
              "biologicTherapyProportion"
            ),
            biologicTherapySwitches: safeTransform(
              () => transformSwitchDataToStackedBarChart(switchesRes.data.data),
              "biologicTherapySwitches"
            ),
            averageNPS: safeTransform(
              () => processNpsData(npsRes.data.data),
              "averageNPS"
            ),
            ostTestPatients: safeTransform(
              () => formatGenderColorData(ostTestPatients.data.data),
              "ostTestPatients"
            ),
            ostTestPatientsByVisit: safeTransform(
              () => convertToStackedBarChartData(ostTestPatientsByVisit.data.data),
              "ostTestPatientsByVisit"
            ),
            ostTestByProgress: safeTransform(
              () => convertDataToChartData(ostTestByProgress.data.data),
              "ostTestByProgress"
            ),
            ostTestByProgressByage: safeTransform(
              () => convertToLineChartDataWithAgeGroups(ostTestByProgressByage.data.data),
              "ostTestByProgressByage"
            ),
            ostTestPatientsByage: safeTransform(
              () => prepareBarChartDataOfOstByAge(ostTestPatientsByage.data.data),
              "ostTestPatientsByage"
            ),
            avgTreatmentNpsByVisit: safeTransform(
              () => avgTreatmentNpsByVisitByLineChart(avgTreatmentNpsByVisit.data.data),
              "avgTreatmentNpsByVisit"
            ),
            avgNpsByGender: safeTransform(
              () => convertToAvgNpsBoxPlotData(avgNpsByGender.data.data),
              "avgNpsByGender"
            ),
            comorbiditiesByPerc: safeTransform(
              () => convertToComorbiditiesPieChartData(comorbiditiesByPerc.data.data),
              "comorbiditiesByPerc"
            ),
            comorbiditiesByfreq: safeTransform(
              () => convertToComorbiditiesBarChartData(comorbiditiesByfreq.data.data),
              "comorbiditiesByfreq"
            ),
            surgicalDataByYear: safeTransform(
              () => convertToBarChartDataByYear(surgicalDataByYear.data.data),
              "surgicalDataByYear"
            ),
            temporalTrendsNps: safeTransform(
              () => convertDataForLineChart(temporalTrendsNps.data.data),
              "temporalTrendsNps"
            ),
            avgSnotByCategory: safeTransform(
              () => avgSnotByCategory.data.data,
              "avgSnotByCategory"
            ),
            temporalTrendsSnot: safeTransform(
              () => convertToChartDataSnotTT(temporalTrendsSnot.data.data),
              "temporalTrendsSnot"
            ),
            surgicalProcedureByPerc: safeTransform(
              () => convertSurgicalPercentage(surgicalProcedureByPerc.data.data),
              "surgicalProcedureByPerc"
            ),
            patientByMedication: safeTransform(
              () => patientByMedication.data.data,
              "patientByMedication"
            ),
            medicationCorrelation: safeTransform(
              () => transformDataForScatterChartByMedication(medicationCorrelation.data.data),
              "medicationCorrelation"
            ),
            genderStatsPatients: safeTransform(
              () => convertToGenderStats(genderStatsPatients.data),
              "genderStatsPatients"
            ),
            ageDitributionPatient: safeTransform(
              () => transformToAgeDistHistogram(ageDitributionPatient.data),
              "ageDitributionPatient"
            ),
            testScoreByAge: safeTransform(
              () => transformDataForBoxPlot(testScoreByAge.data.data),
              "testScoreByAge"
            ),
            // scatterMatrixData:safeTransform(
            //   () => transformMatrixChart(scatterMatrixData.data.data),
            //   "scatterMatrixData"
            // ),
            loading: false,
            error: null,
          });
        } catch (globalError) {
          console.error("Error in setData:", globalError);
          setData((prev) => ({
            ...prev,
            loading: false,
            error: globalError.message,
          }));
        }
        
        function safeTransform(transformFn, fieldName) {
          try {
            return transformFn();
          } catch (e) {
            console.error(`Error transforming data for ${fieldName}:`, e.message);
            return null; // Return a fallback value (null or default) in case of error
          }
        }
      } catch (error) {
        setData((prev) => ({ ...prev, loading: false, error: error.message })); // Set error and loading false
      } finally {
        console.log("comin in finally");
        setData((prev) => ({ ...prev, loading: false })); // Ensure loading is always false
      }
    };
  
    fetchData();

  }, [selectedHospital,selectedPatient]);  



  return (
    <ApiContext.Provider value={{ ...data, setData }}>
      {console.log("Current Context Data:", data)}
      {children}
    </ApiContext.Provider>
  );
};


