import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { BoxPlotController, BoxAndWhisker } from "@sgratzl/chartjs-chart-boxplot";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BoxPlotController,
  BoxAndWiskers,
  Title,
  Tooltip,
  Legend
);

function BoxPlot({ color, title, description, date, chart }) {
  const chartRef = useRef(null); 
  const chartInstanceRef = useRef(null); // Ref for the Chart.js instance

  useEffect(() => {
    // Destroy the existing chart instance before creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create a new Box Plot Chart instance
    const ctx = chartRef.current.getContext("2d");
    chartInstanceRef.current = new ChartJS(ctx, {
      type: "boxplot",
      data: {
        labels: chart?.labels || [], // X-axis labels
        datasets: chart?.datasets || [], // Data for box plots
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              color: "white",
              font: {
                size: 12,
                weight: "bold",
              },
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const { raw } = tooltipItem;
                if (typeof raw === "object") {
                  const { min, q1, median, q3, max } = raw;
                  return `Min: ${min}, Q1: ${q1}, Median: ${median}, Q3: ${q3}, Max: ${max}`;
                }
                return raw;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white",
              font: {
                size: 12,
                weight: "bold",
              },
            },
            grid: {
              display: true,
              color:'#49a3f1'
            },
          },
          y: {
            ticks: {
              color: "white",
              font: {
                size: 12,
                weight: "bold",
              },
            },
            grid: {
              display: true,
              color:'#49a3f1'
            },
          },
        },
      },
    });
  }, [chart]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <MDBox
          variant="gradient"
          bgColor={color}
          borderRadius="lg"
          coloredShadow={color}
          py={2}
          pr={0.5}
          mt={-5}
          height="15.5rem"
        >
          <canvas ref={chartRef} />
        </MDBox>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography
              variant="button"
              color="text"
              fontWeight="light"
            >
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of BoxPlotChart
BoxPlot.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the BoxPlotChart
BoxPlot.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default BoxPlot;
