import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix"; // Correct import

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Register required Chart.js components and the matrix plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  MatrixController,  // Register MatrixController
  MatrixElement      // Register MatrixElement
);

function HeatMap({ color, title, description, date, chart }) {

  const chartRef = useRef(null); // Ref for the canvas element
  const chartInstanceRef = useRef(null); // Ref for the Chart.js instance

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
  
    // Map the rows and columns
    const rows = chart?.rows; // Y-axis labels
    const columns = chart?.columns; // X-axis labels
  
    // Prepare matrix data for heatmap
    const matrixData = [];
    rows?.forEach((row, rowIndex) => {
      chart?.data[rowIndex]?.forEach((value, colIndex) => {
        matrixData?.push({
          x: colIndex, // Column index
          y: rowIndex, // Row index
          v: value, // Heatmap value
        });
      });
    });
  
    // Create the heatmap
    const ctx = chartRef.current.getContext("2d");
    chartInstanceRef.current = new ChartJS(ctx, {
      type: "matrix",
      data: {
        datasets: [
          {
            label: chart.datasetLabel || "Heat Map",
            data: matrixData,
            backgroundColor: (context) => {
              const value = context.raw.v; // Use the "v" value for color mapping
              if (value > 2) return "rgba(0, 255, 0, 0.8)"; // Green for high values
              if (value > 1) return "rgba(255, 165, 0, 0.8)"; // Orange for medium
              return "rgba(255, 0, 0, 0.8)"; // Red for low values
            },
            width: ({ chart }) => chart.scales.x.width / columns.length,
            height: ({ chart }) => chart.scales.y.height / rows.length,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              color: "white",
              font: { size: 12, weight: "bold" },
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const { x, y, v } = tooltipItem.raw; // Destructure correctly
                return `(${rows[y]}, ${columns[x]}): ${v}`;
              },
            },
          },
        },
        scales: {
          x: {
            type: "category",
            labels: columns,
            ticks: { color: "white", font: { size: 12, weight: "bold" } },
            grid: {
              display: true,
              color:'#49a3f1'
            },
          },
          y: {
            type: "category",
            labels: rows,
            ticks: { color: "white", font: { size: 12, weight: "bold" } },
            grid: {
              display: true,
              color:'#49a3f1'
            },
          },
        },
      },
    });
  }, [chart]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <MDBox
          variant="gradient"
          bgColor={color}
          borderRadius="lg"
          coloredShadow={color}
          py={2}
          pr={0.5}
          mt={-5}
          height="15.5rem"
        >
          <canvas ref={chartRef} />
        </MDBox>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography
              variant="button"
              color="text"
              fontWeight="light"
            >
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of HeatMap
HeatMap.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the HeatMap
HeatMap.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    datasetLabel: PropTypes.string,
    backgroundColor: PropTypes.string,
  }).isRequired,
};

export default HeatMap;
