/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password";
// @mui icons
import Icon from "@mui/material/Icon";
import PatientsTables from "layouts/patient_management";
import PhysiciansTables from "layouts/physician_management";
import ForgotPassword from "layouts/authentication/forgot-password";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom";
import { FaList } from "react-icons/fa";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import PatientsHistory from "layouts/patient_history_data";
import ConsentManage from "layouts/consent";
import AdminApproval from "layouts/AdminApproval";
import OTPVerification from "layouts/authentication/OTPVerification";
import Hospitalwidedata from "layouts/Hospital-wide-data";
import PatientDetails from "layouts/PatientDetails";
import { ApiProvider } from "./utils/ApiContext";
import MDBox from "components/MDBox";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SignOut = () => {
  const { setUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  localStorage.clear();
  setUserData({});

  navigate("/authentication/sign-in");

  return null;
};

// FilterHospital Component
const FilterHospital = ({ selectedHospital, onHospitalChange, hospitals }) => {
  const handleHospitalChange = (value) => {
    onHospitalChange(value); // Notify parent about the hospital change
  };

  return (
    <MDBox sx={{ width: 300, mt: 3,mb: 3 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="view-select-label">Select Hsopital</InputLabel>
        <Select
          labelId="view-select-label"
          id="view-select"
          value={selectedHospital}
          label="Select Hospital"
          style={{ paddingTop: "12px", paddingBottom: "12px" }}
          onChange={(event) => handleHospitalChange(event.target.value)}
        >
          {hospitals.map((h, index) => (
            <MenuItem key={index} value={h}>
              {h}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MDBox>
  );
};

// PatientDropdown Component
const PatientDropdown = ({ selectedHospital, selectedPatient, onPatientChange }) => {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    if (!selectedHospital) return;

    const fetchPatients = () => {
      fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/filter-patients/${selectedHospital}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setPatients(data.patients);
            // If no patient is currently selected, set the first one by default
            // if (!selectedPatient && data.patients.length > 0) {
            //   console.log('comin heree in the patient');
            //   console.log(data.patients[0]._id,'getting patient id');
              onPatientChange(data.patients[0]._id);
            // }
          } else {
            setPatients([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching patients:', error);
          setPatients([]);
        });
    };

    fetchPatients();
  }, [selectedHospital, onPatientChange, selectedPatient]);

  console.log(selectedPatient==="",'se patients');

  function isValidObjectId(id) {
    // Check if the input is a string and matches the ObjectId format
    const objectIdRegex = /^[a-f\d]{24}$/i; // Regular expression for ObjectId
    return typeof id === 'string' && objectIdRegex.test(id);
  }

  return (
    <MDBox sx={{ width: 180, mt: 3, mb:3 }}>
    <FormControl fullWidth size="small">
      <InputLabel id="patient-select-label">Select Patient</InputLabel>
      <Select
        labelId="patient-select-label"
        id="patient-select"
        value={selectedPatient || ""} // Bind the selected value
        onChange={(event) => onPatientChange(event.target.value)}
        style={{ paddingTop: "12px", paddingBottom: "12px" }}
        label="Select Patient"
      >
        {patients.map((patient, index) => (
          <MenuItem key={index} value={patient._id}>
            {patient.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </MDBox>
  );
};

// DashboardWrapper Component
const DashboardWrapper = () => {
  const [selectedHospital, setSelectedHospital] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedPatientName,setSelectedPatientName] =  useState("");

  useEffect(() => {
    const fetchHospitals = () => {
      fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/recommend-hospital`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setHospitals(data.hospitals);
            setSelectedHospital(data.hospitals[5]); // Default to the first hospital
          }
        })
        .catch((error) => {
          console.error("Error fetching hospitals:", error);
        });
    };

    fetchHospitals();
  }, []);

  const handleHospitalChange = (hospital) => {
    setSelectedHospital(hospital);
  };

  const handlePatientChange = (patientId) => {
    console.log(patientId,'getting patient Id');
    setSelectedPatient(patientId);
  };

  return (
    <ApiProvider
      selectedHospital={selectedHospital}
      selectedPatient={selectedPatient}
    >
      <Dashboard
        FilterHospital={
          <FilterHospital
            selectedHospital={selectedHospital}
            onHospitalChange={handleHospitalChange}
            hospitals={hospitals}
          />
        }
        PatientDropdown={
        <PatientDropdown
          selectedHospital={selectedHospital}
          selectedPatient={selectedPatient} // Pass current selected patient
          onPatientChange={handlePatientChange} // Update parent state
          />
        }
      />
    </ApiProvider>
  );
};

const routes = [
  {
    type: "collapse",
    name: "Analytics",
    key: "dashboard",
    icon: <RiDashboardHorizontalFill />,
    route: "/dashboard",
    component: <DashboardWrapper />,
  },
  {
    type: "collapse",
    name: "Patient List",
    key: "patient",
    icon: <FaList />,
    route: "/patients",
    component: <PatientsTables />,
  },
  {
    type: "collapse",
    name: "Patient Details",
    key: "patient-details",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/patient/:id",
    component: <PatientDetails />,
  },
  {
    type: "collapse",
    name: "Physician List",
    key: "physician",
    icon: <FaList />,
    route: "/physician",
    component: <PhysiciansTables />,
  },
  {
    type: "collapse",
    name: "Hospital-wide Data",
    key: "hospital-wide-data",
    icon: <Icon fontSize="small">data_usage</Icon>, // Choose an appropriate icon
    route: "/hospital-wide-data",
    component: <Hospitalwidedata />,
    // visible: false,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "Logout",
    icon: <Icon fontSize="small">Logout</Icon>,
    route: "/",
    component: <SignOut />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "OTP Verification",
    key: "otp-verification",
    icon: <Icon fontSize="small">verified_user</Icon>,
    route: "/authentication/otp-verification",
    component: <OTPVerification />, // Add the OTP Verification component here
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">lock_reset</Icon>,
    route: "/authentication/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">lock_reset</Icon>,
    route: "/reset-password/:token",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Consent Manage",
    key: "consent-manage",
    icon: <Icon fontSize="small">lock_reset</Icon>,
    route: "/consent/:token",
    component: <ConsentManage />,
  },
  {
    type: "collapse",
    name: "Admin Approval",
    key: "admin-approval",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/admin/approve/:token",
    component: <AdminApproval />,
  },
];

export default routes;
